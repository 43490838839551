import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import { latLngBounds, divIcon } from "leaflet";
import { Link, useParams  } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import { Box, Divider, Typography, Fab, Breadcrumbs, Grid } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinearProgress from '@mui/material/LinearProgress';
import { useStyles } from "../../styles/styles";
import "leaflet/dist/leaflet.css";

export const Trip = () => {
  const classes = useStyles();
  const { id } = useParams();
  const cols = [
    { field: 'timestamp', valueFormatter: ({value}) => new Date(value).toLocaleString(), headerName: 'Time', type: 'datetime', width: 220, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'speed', headerName: 'Speed', width: 100, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'gear', headerName: 'Mode', width: 100, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'latitude', headerName: 'Latitude', width: 140, headerClassName: classes.tableHeading, cellClassName: classes.tableCell},
    { field: 'longitude', headerName: 'Longitude', width: 140, headerClassName: classes.tableHeading, cellClassName: classes.tableCell},
    { field: 'fault_code', headerName: 'Fault Code', width: 140, headerClassName: classes.tableHeading, cellClassName: classes.tableCell},
    { field: 'system_fault', headerName: 'System Fault', width: 140, headerClassName: classes.tableHeading, cellClassName: classes.tableCell},
    { field: 'voltage', headerName: 'Voltage', width: 120, headerClassName: classes.tableHeading, cellClassName: classes.tableCell},
    { field: 'current', headerName: 'Current', width: 120, headerClassName: classes.tableHeading, cellClassName: classes.tableCell},
    { field: 'temperature', headerName: 'Temperature', width: 140, headerClassName: classes.tableHeading, cellClassName: classes.tableCell},
    { field: 'capacity', headerName: 'Capacity', width: 120, headerClassName: classes.tableHeading, cellClassName: classes.tableCell},
    { field: 'charge', headerName: 'Charge', width: 120, headerClassName: classes.tableHeading, cellClassName: classes.tableCell},
  ];

  const [tripData, setTripData] = useState([]);
  const [trip, setTrip] = useState({});
  const [loading, setLoading] = useState(true);
  const [nextPage, setNextPage] = useState(`https://api.paradigmevs.live/api/tripdata/${id}/?limit=20`);

  const fetchTripData = async () => {
    if (!nextPage) return;
    setLoading(true);
    const res = await fetch(nextPage);
    const data = await res.json();
    data.results.vehicle_events.forEach(event => {
      event.voltage = event.battery_event.voltage;
      event.current = event.battery_event.current;
      event.temperature = event.battery_event.temperature;
      event.capacity = event.battery_event.capacity;
      event.charge = event.battery_event.charge;
    });
    setTrip(data.results.trip);
    setTripData(rows => [...rows, ...data.results.vehicle_events]);
    setNextPage(data.next);
    setLoading(false);
  }

  useEffect(() => {
    fetchTripData();
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{height: "calc(100vh - 150px)"}}>
      <Breadcrumbs>
        <Link className={classes.heading} underline="hover" to="/trips">Trips</Link>
        <Typography className={classes.heading}>
          {id}
        </Typography>
      </Breadcrumbs>
      <Divider />

      <Grid container spacing={1} >
        <Grid item xs={6}>
          <Box sx={{
              height: "252px",
              borderRadius: 1, 
              p: "24px",
              m: "12px",
              bgcolor: '#f8f9fa',
              boxShadow: 3,
            }}>
            <Typography variant="h6">Trip Details</Typography>
            <Typography>Vehicle: {trip.vehicle}</Typography>
            <Typography>Start: {new Date(trip.start_timestamp).toLocaleString()}</Typography>
            <Typography>End: {new Date(trip.end_timestamp).toLocaleString()}</Typography>
            <Typography>Battery: {trip.battery}</Typography>
            <Typography>Distance Travelled: {trip.distance_travelled}</Typography>
            <Typography>Energy Consumed: {trip.energy_consumed}</Typography>
            <Typography>Average Speed: {parseFloat(trip.avg_speed).toFixed(2)}</Typography>
            <Typography>Top Speed: {parseFloat(trip.top_speed).toFixed(2)}</Typography>
            <Typography>Energy Consuption / Km: {(trip.energy_consumed/trip.distance_travelled).toFixed(2)}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
        <Box sx={{ 
              height: "300px",
              borderRadius: 1,
              m: "12px",
              bgcolor: '#f8f9fa',
              boxShadow: 3,
            }}>
            <MapContainer
              center={[24.89, 67.02]}
              zoom={13}
              style={{ height: "100%", width: "100%" }}
              scrollWheelZoom={false}
            >
              <SetMapView locs={tripData} />
              <TileLayer
                url="https://tile.jawg.io/jawg-streets/{z}/{x}/{y}{r}.png?access-token=wFdgjea1XXXOpQMoeqPYWoknkZ4lCzHLHhPj2W3N4dP2S4nia6BoIJ3uvXASP2oB"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              {tripData.map((ride, key) => (
                <Marker key={key} position={[ride.latitude, ride.longitude]} icon={divIcon({className: classes.dot})}>
                  <Popup>
                    Time: {ride.timestamp} <br/>
                    Speed: {ride.speed} <br/>
                    Gear: {ride.gear} <br/>
                    Fault Code: {ride.fault_code} <br/>
                    System Fault: {ride.system_fault}
                  </Popup>
                </Marker>
              ))}
            </MapContainer>
          </Box>
        </Grid>
      </Grid>
      
      <DataGrid
        className={classes.dataTable}
        sx={{ height: "calc(100vh - 480px) !important" }}
        rows={tripData}
        slots={{ loadingOverlay: LinearProgress }}
        loading={loading}
        columns={cols}
        hideFooter
      />
      <Fab size="small" color="primary" disabled={!nextPage} sx={{position: "absolute", bottom: 30, right: 30}} onClick={fetchTripData}>
        <ExpandMoreIcon />
      </Fab>
    </Box>
  );
}

const SetMapView = ({locs}) => {
  const map = useMap();
  if (locs.length) {
    map.setView([locs[0].latitude, locs[0].longitude], 15);
    const markerBounds = latLngBounds([]);
    locs.forEach(loc => {
      markerBounds.extend([loc.latitude, loc.longitude]);
    });
    map.fitBounds(markerBounds);
  }
  return null;
}