import React from "react";
import { NavLink } from "react-router-dom";
import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { 
  Map,
  Route,
  TwoWheelerRounded,
  EvStation,
  Battery6BarTwoTone,
  OfflineBoltOutlined,
} from "@mui/icons-material";
import { useStyles } from "../styles/styles";
import Logo from "../paradigm evs 3.png";

export const SideBar = () => {
  const classes = useStyles();
  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar>
        <NavLink to="/">
          <img src={Logo} alt="Logo" className={classes.logo} />
        </NavLink>
      </Toolbar>
      <List>
        <ListItem to="map" name="Map View">
          <Map />
        </ListItem>
        <ListItem to="vehicles" name="Vehicles">
          <TwoWheelerRounded />
        </ListItem>
        <ListItem to="trips" name="Trips">
          <Route sx={{ transform: "scaleX(-1)" }} />
        </ListItem>
        <ListItem to="batteries" name="Batteries">
          <Battery6BarTwoTone sx={{ transform: "rotate(90deg)" }} />
        </ListItem>
        <ListItem to="charge_cycles" name="Charge Cycles">
          <OfflineBoltOutlined className={classes.icons} />
        </ListItem>
        <ListItem to="stations" name="Stations">
          <EvStation className={classes.icons} />
        </ListItem>
      </List>
    </Drawer>
  );
};

const ListItem = ({ children, to, name }) => {
  const classes = useStyles();
  return (
    <ListItemButton
      component={NavLink}
      to={"/" + to}
      key={to}
      className={classes.link}
    >
      <ListItemIcon className={classes.icons}>{children}</ListItemIcon>
      <ListItemText primary={name} />
    </ListItemButton>
  );
};
