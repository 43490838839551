import React, { useState, useEffect } from "react";
import { DataGrid } from '@mui/x-data-grid';
import { Box, Divider, Typography } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import { useStyles } from "../../styles/styles";

export const BatteryTable = () => {
  const classes = useStyles();

  const columns = [
    { field: 'number', headerName: 'Battery ID', width: 240, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'asset', headerName: 'Plugged In', width: 220, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'asset_type', headerName: 'Asset Type', width: 180, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'status', headerName: 'Live Staus', width: 240, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'manufacturing_date', headerName: 'Date Manufactured', type: "datetime", width: 270, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'distance_travelled', headerName: 'Distance Travelled', width: 240, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'energy_consumed', headerName: 'Energy Consumed', width: 240, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
  ];
  
  const [batteryRows, setBatteryRows] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBatteries = async () => {
      setLoading(true);
      const res = await fetch("https://api.paradigmevs.live/api/batteries/");
      const data = await res.json();
      setBatteryRows(data.results);
      setLoading(false);
    }
    fetchBatteries();
  }, []);
  
  return (
    <Box>
      <Typography className={classes.heading}>
        Batteries
      </Typography>
      <Divider />
      <DataGrid className={classes.dataTable} slots={{ loadingOverlay: LinearProgress }} loading={loading} rows={batteryRows} columns={columns} hideFooter/>
    </Box>
  );
}
