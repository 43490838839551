import React, { useState } from "react";
import { AppBar, Toolbar, IconButton, Badge, Tooltip, Menu, MenuItem, Avatar, ListItemIcon, Divider } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { useStyles } from "../styles/styles";

export const NavBar = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => setAnchorEl(null);

  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <div className={classes.grow} />
        {/* <Icon name="Notifications" badge={4}>
          <CircleNotificationsIcon />
        </Icon>
        <Icon name="Support">
          <HelpIcon />
        </Icon> */}
        <Icon
          name="Account settings"
          onClick={(e) => setAnchorEl(e.currentTarget)}
          aria-controls={Boolean(anchorEl) ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
        >
          <AccountCircle />
        </Icon>
      </Toolbar>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 3px 10px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose}>
          <Avatar /> Profile
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </AppBar>
  );
};

const Icon = ({ children, name, badge, ...props }) => {
  const classes = useStyles();
  return (
    <Tooltip title={name}>
      <IconButton className={classes.icons} {...props}>
        <Badge badgeContent={badge} color="error">
          {children}
        </Badge>
      </IconButton>
    </Tooltip>
  );
};
