import React, { useEffect, useState } from "react";
import { useNavigate  } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import { Box, Divider, Typography, Fab } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinearProgress from '@mui/material/LinearProgress';
import { useStyles } from "../../styles/styles";

export const TripTable = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const cols = [
    { field: 'id', headerName: 'Trip ID', width: 80, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'vehicle', headerName: 'Vehicle ID', width: 220, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'battery', headerName: 'Battery ID', width: 200, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'start_timestamp', valueFormatter: ({value}) => new Date(value).toLocaleString(), headerName: 'Start Time', type: 'datetime', width: 230, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'duration', valueGetter: ({row}) => (new Date(row.end_timestamp || Date.now()) - new Date(row.start_timestamp)) / 1000, valueFormatter: ({value}) => (Math.floor(value/3600)) ? `${Math.floor(value / 3600)}h ${Math.floor(value % 3600 /60)}m`: `${Math.floor(value % 3600 /60)}m`, headerName: 'Duration', width: 140, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'end_timestamp', valueFormatter: ({value}) => value ? new Date(value).toLocaleString() : "Ongoing", headerName: 'End Time', type: 'datetime', width: 230, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'distance_travelled', headerName: 'Distance (km)', width: 160, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'energy_consumed', headerName: 'Energy Consumed (Wh)', width: 240, headerClassName: classes.tableHeading, cellClassName: classes.tableCell},
    { field: 'data_points', headerName: 'Data Points', width: 120, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
  ];

  const [tripRows, setTripRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nextPage, setNextPage] = useState("https://api.paradigmevs.live/api/trips/?limit=20");

  const fetchTrips = async () => {
    if (!nextPage) return;
    setLoading(true);
    const res = await fetch(nextPage);
    const data = await res.json();
    setTripRows(rows => [...rows, ...data.results]);
    setNextPage(data.next);
    setLoading(false);
  }

  useEffect(() => {
    fetchTrips();
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      <Typography className={classes.heading}>
        Trips
      </Typography>
      <Divider />
      <DataGrid
        className={classes.dataTable}
        rows={tripRows}
        slots={{ loadingOverlay: LinearProgress }}
        loading={loading}
        columns={cols}
        onRowClick={({id}) => navigate(`/trips/${id}`) }
        hideFooter
      />
      <Fab size="small" color="primary" disabled={!nextPage} sx={{position: "absolute", bottom: 30, right: 30}} onClick={fetchTrips}>
        <ExpandMoreIcon />
      </Fab>
    </Box>
  );
}
