import { makeStyles } from "@mui/styles";

const drawerWidth = 220;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#0B132B",
    color: "#dbd3db",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
  },
  appBar: {
    height: "56px",
    width: `calc(100% - ${drawerWidth}px)`,
    backgroundImage: "linear-gradient(to right, #0B132B , #000)",
  },
  logo: {
    width: drawerWidth * 0.8,
    marginRight: theme.spacing(2),
  },
  link: {
    textDecoration: "none",
    color: "inherit",
    borderRadius: "8px",
    margin: "8px",
    "&.active": {
      backgroundColor: "#1C2541",
    },
    "&:hover": {
      backgroundColor: "#1C2541",
      boxShadow: "0 5px 10px #000",
    },
  },
  grow: {
    flexGrow: 1,
  },
  icons: {
    color: "#dbd3db",
  },
  heading: {
    padding: "10px",
    color: "#1C2541",
    fontSize: "1.6rem",
    textDecoration: "none",
  },
  headingActive: {
    padding: "10px",
    color: "#1C2541",
    fontSize: "1.6rem",
    textTransform: "uppercase",
    borderBottom: "1px solid #1C2541",
  },
  tableHeading: {
    color: "#001233",
    fontSize: "1.1rem",
  },
  tableCell: {
    color: "#33415c",
    fontSize: "1.1rem",
  },
  dot: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    display: "inline-block",
    // marginRight: "8px",
    backgroundColor: "#001233",
  },
  locationCard: {
    width: "20vw",
    borderRadius: "12px",
    padding: "24px",
    margin: "0px 16px",
    backgroundColor: "#f8f9fa",
    boxShadow: "3px 7px 10px -2px #00123366,0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
    zIndex: 1000,
  },
  dataTable: {
    margin: "10px",
    width: "calc(100vw - 260px)",
    height: "calc(100vh - 160px)",
  },
}));
