import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { SideBar } from "./components/Drawer";
import { NavBar } from "./components/NavBar";
import { useStyles } from "./styles/styles";
import { UserTable } from "./components/Users/UserTable";
import { Box, Toolbar, Typography } from "@mui/material";
import { VehicleTable } from "./components/Vehicles/VehicleTable";
import { StationTable } from "./components/Stations/StationTable";
import { BatteryTable } from "./components/Batteries/BatteryTable";
import { MapView } from "./components/MapView/MapView";
import { TripTable } from "./components/Trips/TripTable";
import { Trip } from "./components/Trips/Trip";
import { ChargeCycleTable } from "./components/ChargeCycles/ChargeCycleTable";
import { ChargeCycle } from "./components/ChargeCycles/ChargeCycle";

const theme = createTheme();

function Dashboard() {
  const classes = useStyles();

  return (
    <Router>
      <Box className={classes.root}>
        <SideBar />
        <NavBar />
        <Box className={classes.content}>
          <Toolbar />
          <Routes>
            <Route path="/" element={ <Navigate to="/map" replace={true} />} />
            <Route path="/map" element={<MapView />} />
            <Route path="/users" element={<UserTable />} />
            <Route path="/trips" element={<TripTable/>} />
            <Route path="/trips/:id/" element={<Trip />} />
            <Route path="/vehicles" element={<VehicleTable />} />
            <Route path="/stations" element={<StationTable />} />
            <Route path="/charge_cycles" element={<ChargeCycleTable />} />
            <Route path="/charge_cycles/:id/" element={<ChargeCycle />} />
            <Route path="/batteries" element={<BatteryTable />} />
            <Route path="*" element={<Typography variant="h3">Page Not Found</Typography>} />
          </Routes>
        </Box>
      </Box>
    </Router>
  );
}

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Dashboard />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
