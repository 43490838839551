import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MapContainer, TileLayer, ZoomControl, Marker, Popup, useMap } from "react-leaflet";
import { Box, Typography, Card, CardContent, Grid, Divider } from "@mui/material";
import { icon, latLngBounds } from "leaflet";
import "leaflet/dist/leaflet.css";
import { useStyles } from "../../styles/styles";

export const MapView = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [locations, setLocations] = useState({vehicles:[], stations:[]}); // {vehicles, stations}
  const [vehicles, setVehicles] = useState([]);
  const [stats, setStats] = useState({});
  
  const fetchLocations = async () => {
    const res = await fetch("https://api.paradigmevs.live/api/stations/");
    const stations = await res.json();
    
    const res2 = await fetch("https://api.paradigmevs.live/api/vehicles/");
    const vehicles = await res2.json();

    setLocations({
      vehicles: vehicles.results.map((vehicle) => vehicle.location),
      stations: stations.results,
    });
    setVehicles(vehicles.results);
  };

  const fetchStats = async () => {
    const res = await fetch("https://api.paradigmevs.live/api/stats/");
    const data = await res.json();
    setStats(data);
  };

  useEffect(() => {
    fetchLocations();
    fetchStats();
  }, []);

  return (
    <Box sx={{ display: "flex", width: "calc(100vw - 240px)", height: "calc(100vh - 90px)"}}>
      <Box className={classes.locationCard}>
        <Typography variant="h5">Stats</Typography>
        <Divider sx={{m:1.5}} />
        <Grid container spacing={2} >
          <Grid item xs={6}>
            <Card>
              <CardContent>
                <Typography color="text.secondary">Active Users</Typography>
                <Typography variant="h5">{stats.active_user_count}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card>
              <CardContent>
                <Typography color="text.secondary">Active Batteries</Typography>
                <Typography variant="h5">{stats.active_battery_count}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card>
              <CardContent>
                <Typography color="text.secondary">Active Stations</Typography>
                <Typography variant="h5">{stats.active_stations_count}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card>
              <CardContent>
                <Typography color="text.secondary">Total KMs Driven</Typography>
                <Typography variant="h5">{stats.total_kilometers}</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Typography sx={{mt: 5}} variant="h5">Recent Trips</Typography>
        <Divider sx={{m:1.5}} />
        <Grid container spacing={2} sx={{overflowY:"auto", height:"calc(100vh - 490px)"}} >
          {vehicles.length ? vehicles.map((vehicle, index) => (
            <Grid item xs={12} key={index}>
              <Card sx={{cursor:"pointer", mr:1}} onClick={()=>navigate(`/trips/${vehicle.last_trip.id}`)}>
                <CardContent>
                  <Typography color="text.secondary">Vehicle {vehicle.number}</Typography>
                  <Typography>Start Time: {new Date(vehicle.last_trip.start_timestamp).toLocaleString()}</Typography>
                  <Typography>End Time: {new Date(vehicle.last_trip.end_timestamp).toLocaleString()}</Typography>
                  <Typography>Battery: {vehicle.last_trip.battery}</Typography>
                  <Typography>Distance Travelled: {vehicle.last_trip.distance_travelled}</Typography>
                  <Typography>Energy Consumed: {vehicle.last_trip.energy_consumed}</Typography>
                  <Typography>Status: {vehicle.last_trip.status}</Typography>
                </CardContent>
              </Card>
            </Grid>
          )) : <Grid item xs={12}><Typography>No recent trips</Typography></Grid>}
        </Grid>

      </Box>
      <Box sx={{flexGrow: 1}}>
        <MapContainer
          center={[24.89, 67.02]}
          zoom={13}
          style={{ height: "100%", width: "100%" }}
          zoomControl={false}
        >
          <SetMapView locs={[...locations.stations, ...locations.vehicles]} />
          <TileLayer
            url="https://tile.jawg.io/jawg-streets/{z}/{x}/{y}{r}.png?access-token=wFdgjea1XXXOpQMoeqPYWoknkZ4lCzHLHhPj2W3N4dP2S4nia6BoIJ3uvXASP2oB"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
          {locations.vehicles.map((vehicle, index) => (
            <Marker key={index} position={[vehicle.latitude, vehicle.longitude]} icon={icon({iconUrl: "/bike.png", iconSize: [80, 60]})}>
              <Popup>{`Located at ${vehicle.located_at}`}</Popup>
            </Marker>
          ))}
          {locations.stations.map((station, index) => (
            <Marker key={index} position={[station.latitude, station.longitude]} icon={icon({iconUrl: "/station.png", iconSize: [35, 35]})}>
              <Popup>{`Station: ${station.name}`}</Popup>
            </Marker>
          ))}
          <ZoomControl position="bottomright" />

        </MapContainer>
      </Box>
    </Box>
  );
};

const SetMapView = ({locs}) => {
  const map = useMap();
  if (locs.length) {
    map.setView([locs[0].latitude, locs[0].longitude], 15);
    const markerBounds = latLngBounds([]);
    locs.forEach(loc => {

      markerBounds.extend([loc.latitude, loc.longitude]);
    });
    map.fitBounds(markerBounds);
  }
  return null;
}
