import React, { useState, useEffect } from "react";
import { DataGrid } from '@mui/x-data-grid';
import { Box, Divider, Typography } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import { useStyles } from "../../styles/styles";

export const StationTable = () => {
  const classes = useStyles();

  const columns = [
    { field: 'name', headerName: 'Station ID', width: 300, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'latitude', headerName: 'Latitude', width: 240, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'longitude', headerName: 'Longitude', width: 240, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'last_used', valueFormatter: ({value}) => new Date(value).toLocaleString(), headerName: 'Last Used', type: 'datetime', width: 270, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'batteries', headerName: 'Batteries Assigned', width: 270, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    ];

  const [stations, setStations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStations = async () => {
      setLoading(true);
      const res = await fetch("https://api.paradigmevs.live/api/stations/");
      const data = await res.json();
      setStations(data.results);
      setLoading(false);
    }
    fetchStations();
  }, []);
  
  return (
    <Box>
      <Typography className={classes.heading}>
        Stations
      </Typography>
      <Divider />
      <DataGrid className={classes.dataTable} slots={{ loadingOverlay: LinearProgress }} rows={stations} columns={columns} loading={loading} hideFooter />
    </Box>
  );
}
