import React, { useState, useEffect } from "react";
import { DataGrid } from '@mui/x-data-grid';
import { Box, Divider, Typography } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import { useStyles } from "../../styles/styles";

export const VehicleTable = () => {
  const classes = useStyles();

  const cols = [
    { field: 'number', headerName: 'Vehicle ID', width: 240, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'license_number', headerName: 'License Number', width: 180, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'current_owner', headerName: 'Driver', width: 160, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'last_trip', valueFormatter: ({value}) => new Date(value.end_timestamp).toLocaleString(), headerName: 'Last Trip at', type: 'datetime', width: 270, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'batteries', headerName: 'Batteries Assigned', width: 320, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'manufacturing_date', headerName: 'Manufacturing Date', type: 'datetime', width: 270, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'vehicle_model', headerName: 'Bike Model', width: 200, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
  ];

  const [vehicleRows, setVehicleRows] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVehicles = async () => {
      setLoading(true);
      const res = await fetch("https://api.paradigmevs.live/api/vehicles/");
      const data = await res.json();
      setVehicleRows(data.results);
      setLoading(false);
    }
    fetchVehicles();
  }, []);

  return (
    <Box>
      <Box sx={{display:"flex"}}>
        <Typography className={classes.heading} >
          Vehicles
        </Typography>
      </Box>

      <Divider />
      <DataGrid className={classes.dataTable} slots={{ loadingOverlay: LinearProgress }} rows={vehicleRows} columns={cols} loading={loading} hideFooter />
    </Box>
  );
}
