import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { Box, Divider, Typography } from "@mui/material";
import { useStyles } from "../../styles/styles";

export const UserTable = () => {
  const classes = useStyles();

  const columns = [
    { field: 'name', headerName: 'Name', width: 300, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'vehicle', headerName: 'Bike Owned', width: 240, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'email', headerName: 'Email', width: 270, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'phone_number', headerName: 'Phone Number', width: 270, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'last_trip', headerName: 'Last Trip', type: 'datetime', width: 270, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'status', headerName: 'Status', width: 90, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
  ];
  
  const rows = [
    { name: "Yabudullah", vehicle: "BCD-1234", last_trip: "2024-02-10T10:00:00", email: "user@example.com ", status: "Idle", phone_number: "1234567890" },
    { name: "Abdullah", vehicle: "BDE-2345", last_trip: "2024-03-12T15:43:56", email: "user@example.com", status: "Riding", phone_number: "1234567890" },
    { name: "Yusuf", vehicle: "CDE-3456", last_trip: "2024-04-15T18:00:00", email: "user@example.com", status: "Idle", phone_number: "1234567890" },
    { name: "Lakshman", vehicle: "DEF-4567", last_trip: "2024-05-20T20:00:00", email: "user@example.com", status: "Idle", phone_number: "1234567890" },
    { name: "Rahul", vehicle: "EFG-5678", last_trip: "2024-06-25T22:00:00", email: "user@example.com", status: "Riding", phone_number: "1234567890" },
    { name: "Ali", vehicle: "FGH-6789", last_trip: "2024-07-30T00:00:00", email: "user@example.com", status: "Riding", phone_number: "1234567890" },
    { name: "Rahim", vehicle: "GHI-7890", last_trip: "2024-08-30T02:00:00", email: "user@example.com", status: "Idle", phone_number: "1234567890" },
    { name: "Laiba", vehicle: "HIJ-8901", last_trip: "2024-09-30T04:00:00", email: "user@example.com", status: "Idle", phone_number: "1234567890" },
    { name: "Marium", vehicle: "IJK-9011", last_trip: "2024-10-30T06:00:00", email: "user@example.com", status: "Riding", phone_number: "1234567890" },
    { name: "Kynzay", vehicle: "JKL-0123", last_trip: "2024-11-30T08:00:00", email: "user@example.com", status: "Riding", phone_number: "1234567890" },
    { name: "Sumaika", vehicle: "IJM-9012", last_trip: "2024-10-30T06:00:00", email: "user@example.com", status: "Riding", phone_number: "1234567890" },
  ];
  
  return (
    <Box>
      <Typography className={classes.heading}>
        Users
      </Typography>
      <Divider />
      <DataGrid sx={{marginTop: "10px"}} rows={rows} columns={columns} hideFooter getRowId={row => row.vehicle}/>
    </Box>
  );
}
