import React, { useState, useEffect } from "react";
import { Link, useParams  } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import { Box, Divider, Typography, Fab, Breadcrumbs, Grid } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinearProgress from '@mui/material/LinearProgress';
import { useStyles } from "../../styles/styles";
import "leaflet/dist/leaflet.css";

export const ChargeCycle = () => {
  const classes = useStyles();
  const { id } = useParams();
  const cols = [
    { field: 'timestamp', headerName: 'Time', type: 'datetime', width: 240, headerClassName: classes.tableHeading, cellClassName: classes.tableCell },
    { field: 'voltage', headerName: 'Voltage', width: 220, headerClassName: classes.tableHeading, cellClassName: classes.tableCell},
    { field: 'current', headerName: 'Current', width: 220, headerClassName: classes.tableHeading, cellClassName: classes.tableCell},
    { field: 'temperature', headerName: 'Temperature', width: 240, headerClassName: classes.tableHeading, cellClassName: classes.tableCell},
    { field: 'capacity', headerName: 'Capacity', width: 220, headerClassName: classes.tableHeading, cellClassName: classes.tableCell},
    { field: 'charge', headerName: 'Charge', width: 220, headerClassName: classes.tableHeading, cellClassName: classes.tableCell},
  ];

  const [chargeCycleData, setChargeCycleData] = useState([]);
  const [chargeCycle, setChargeCycle] = useState({});
  const [loading, setLoading] = useState(true);
  const [nextPage, setNextPage] = useState(`https://api.paradigmevs.live/api/charge_cycles/${id}/?limit=20`);

  const fetchChargeCycleData = async () => {
    if (!nextPage) return;
    setLoading(true);
    const res = await fetch(nextPage);
    const data = await res.json();
    setChargeCycle(data.results.charge_cycle);
    setChargeCycleData(rows => [...rows, ...data.results.battery_events]);
    setNextPage(data.next);
    setLoading(false);
  }

  useEffect(() => {
    fetchChargeCycleData();
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{height: "calc(100vh - 150px)"}}>
      <Breadcrumbs>
        <Link className={classes.heading} underline="hover" to="/charge_cycles">Charge Cycle</Link>
        <Typography className={classes.heading}>
          {id}
        </Typography>
      </Breadcrumbs>
      <Divider />

      <Grid container spacing={1} >
        <Grid item xs={6}>
          <Box sx={{
              height: "252px",
              borderRadius: 1, 
              p: "24px",
              m: "12px",
              bgcolor: '#f8f9fa',
              boxShadow: 3,
            }}>
            <Typography variant="h6">Charge Cycle Details</Typography>
            <Typography>Battery: {chargeCycle.battery}</Typography>
            <Typography>Start: {new Date(chargeCycle.start_timestamp).toLocaleString()}</Typography>
            <Typography>End: {new Date(chargeCycle.end_timestamp).toLocaleString()}</Typography>
            <Typography>Energy Stored: {chargeCycle.energy_stored}</Typography>
            <Typography>Capacity: {chargeCycle.capacity}</Typography>
            {/* <Typography>Top Speed: {parseFloat(chargeCycle.top_speed).toFixed(2)}</Typography>
            <Typography>Energy Consuption / Km: {(chargeCycle.energy_consumed/chargeCycle.distance_travelled).toFixed(2)}</Typography> */}
          </Box>
        </Grid>
      </Grid>
      
      <DataGrid
        className={classes.dataTable}
        sx={{ height: "calc(100vh - 480px) !important" }}
        rows={chargeCycleData}
        slots={{ loadingOverlay: LinearProgress }}
        loading={loading}
        columns={cols}
        hideFooter
      />
      <Fab size="small" color="primary" disabled={!nextPage} sx={{position: "absolute", bottom: 30, right: 30}} onClick={fetchChargeCycleData}>
        <ExpandMoreIcon />
      </Fab>
    </Box>
  );
}
